<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 13.2501H3.75C3.336 13.2501 3 12.9141 3 12.5001C3 12.0861 3.336 11.7501 3.75 11.7501H20.25C20.664 11.7501 21 12.0861 21 12.5001C21 12.9141 20.664 13.2501 20.25 13.2501Z"
      fill="currentColor"
    />
    <path
      d="M20.25 7.5H3.75C3.336 7.5 3 7.164 3 6.75C3 6.336 3.336 6 3.75 6H20.25C20.664 6 21 6.336 21 6.75C21 7.164 20.664 7.5 20.25 7.5Z"
      fill="currentColor"
    />
    <path
      d="M20.25 18.9999H3.75C3.336 18.9999 3 18.6639 3 18.2499C3 17.8359 3.336 17.4999 3.75 17.4999H20.25C20.664 17.4999 21 17.8359 21 18.2499C21 18.6639 20.664 18.9999 20.25 18.9999Z"
      fill="currentColor"
    />
  </svg>
</template>
